import React, { useContext } from 'react';
import { Form } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikSubmit from '../../MuiFormikComponents/FormikSubmit';
import FormikTextInput from '../../MuiFormikComponents/FormikTextInput';
import FormikDropDown from '../../MuiFormikComponents/FormikDropDown';
import Contexts from '../../../../Contexts';
import { generateSportDropDownList } from '../../../../Utils/dropDownListGenerators';
import ChannelSearchFormComponent from '../../MuiFormikComponents/ChannelSearchFormComponent/ChannelSearchFormComponent';
import ReactDatePicker from '../../MuiFormikComponents/ReactDatePicker';

const FormFields = ({ isForManagedHomeFeed = false }) => {
  const classes = useStyles();

  const context = useContext(Contexts);
  const { formConfig } = context;

  return (
    <Form>
      <Paper
        elevation={3}
        className={classes.formContainer}
        style={{
          maxWidth: !isForManagedHomeFeed ? '600px' : '100%',
          marginTop: isForManagedHomeFeed && 0,
        }}
      >
        <Grid container spacing={3}>
          {!isForManagedHomeFeed && (
            <Grid item xs={12}>
              <Typography variant="h4">Stream Search</Typography>
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={6} lg={!isForManagedHomeFeed ? 4 : 3}>
            <FormikTextInput label="Title" name="title" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={!isForManagedHomeFeed ? 4 : 3}>
            <FormikDropDown
              valueDisplayNameMap={[
                { value: 'SCHEDULED', displayName: 'Scheduled' },
                { value: 'VIDEO_RECEIVED', displayName: 'Video Received' },
                { value: 'VIDEO_READY', displayName: 'Video Ready' },
                { value: 'UPLOADING', displayName: 'Uploading' },
                { value: 'UPLOAD_COMPLETE', displayName: 'Uploading Complete' },
              ]}
              label="STATUS"
              name="highlight_mixer_vas_status"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={!isForManagedHomeFeed ? 4 : 3}>
            <FormikDropDown
              valueDisplayNameMap={generateSportDropDownList(formConfig)}
              label="Category"
              name="category"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={!isForManagedHomeFeed ? 4 : 3}>
            <ChannelSearchFormComponent name="channel_id" label="Channel" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={!isForManagedHomeFeed ? 4 : 3}>
            <FormikTextInput label="SV Match Id" name="stream_key" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={!isForManagedHomeFeed ? 4 : 3}>
            <FormikTextInput label="Pix Event Id" name="pixellot_event_id" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={3}>
            <ReactDatePicker disableTime label="Start Date" name="start_date" />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <ReactDatePicker disableTime label="End Date" name="end_date" />
          </Grid>

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="Search" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;

import {
  CDN_MUMBAI_DATA_OUT_EGRESS_COST_PER_GB,
  RegionBasedS3CostsConfig,
  SERVER_GP3_STORAGE_GB,
  TAX_MULTIPLIER,
  regionAndTypeBasedEc2CostsConfig,
} from './configs';
import { CostRequest, Ec2Type } from './types';

const testRequest: CostRequest = {
  // -------- server controlled --------------------

  previewAudioBitrateKbps: 192,
  previewVideoBitrateKbps: 2000, // 480p previews for video inputs

  hlsQualityLevels: [
    { bitrateKbps: 5000, name: '1080p' },
    { bitrateKbps: 2800, name: '720p' },
    { bitrateKbps: 1400, name: '480p' },
  ],

  s3Region: 'us-east-1',

  streamS3StorageFAMonths: 1,
  streamS3StorageIFAMonths: 3,
  streamS3StorageAIAMonths: 8,

  highlightS3StorageFAMonths: 1,
  highlightS3StorageIFAMonths: 0,
  highlightS3StorageAIAMonths: 0,

  hlsSegmentDurationSeconds: 6,

  // ------ user determined --------------------------

  serverRegion: 'ap-south-1',
  audioCommentators: 0,
  producers: 0,
  videoInputs: 1,

  outputDestinations: [
    // { bitrateKbps: 5000, region: 'internet' },
    // { bitrateKbps: 5000, region: 'ap-south-1' },
  ],

  // ------- manual clipping related ------------
  // set to zero if not using manual clipping

  numberOfHighlightClippersAndQA: 0,

  numberOfHighlightDownloads: 0,

  minsOfHighlightsPerHour: 0,

  // --------- cdn related ---------------

  avgNumberOfUsersWhoWillWatchFullStream: 200,
};

const getGBPerHour = (args: { bitrateKbps: number }) => {
  const oneHourKB = (args.bitrateKbps / 8) * 60 * 60;
  const oneHourMB = oneHourKB / 1024;
  const oneHourGB = oneHourMB / 1024;

  return oneHourGB;
};

const appendToString = (original: string, append: string) =>
  `${original}${append}`;

export const calculateCosts = request => {
  let breakDownString = '=*=*=* BREAKDOWN =*=*=*';

  // ================ SECTION 1: Cloud Production ===============================

  const ec2Type: Ec2Type =
    request.videoInputs > 2 ? 'g4dn.2x_large' : 'g4dn.x_large';
  const ec2Costs =
    regionAndTypeBasedEc2CostsConfig[request.serverRegion][ec2Type];

  // --------- DATA OUT 1: previews ---------------------

  const GB_perVideoPreview = getGBPerHour({
    bitrateKbps:
      request.previewVideoBitrateKbps + request.previewAudioBitrateKbps,
  });

  const GB_perAudioCommentatorPreview = getGBPerHour({
    bitrateKbps: request.previewAudioBitrateKbps,
  });

  // +1 since producer will see final video out too
  const COST_perProducer =
    (GB_perVideoPreview * (request.videoInputs + 1) +
      GB_perAudioCommentatorPreview * request.audioCommentators) *
    ec2Costs.regionCosts.dataOutCostsPerGbToInternet;

  const COST_perAudioCommentator =
    (GB_perVideoPreview * request.videoInputs +
      GB_perAudioCommentatorPreview *
        Math.max(0, request.audioCommentators - 1)) *
    ec2Costs.regionCosts.dataOutCostsPerGbToInternet;

  const TOTAL_COST_previews_data_out =
    COST_perProducer * request.producers +
    COST_perAudioCommentator * request.audioCommentators;

  // --------- DATA OUT 2: HLS s3 upload ---------------------

  let totalHlsBitrateKbps = 0;
  request.hlsQualityLevels.forEach(e => (totalHlsBitrateKbps += e.bitrateKbps));

  const GB_perHourHls = getGBPerHour({
    bitrateKbps: totalHlsBitrateKbps,
  });

  const TOTAL_COST_hls_s3_data_out =
    GB_perHourHls *
    ec2Costs.regionCosts.dataOutCostsPerGbToRegion[request.s3Region];

  // --------- DATA OUT 3: outputDestinations ---------------------

  let TOTAL_COST_destinations_data_out = 0;
  request.outputDestinations.forEach(od => {
    let dataOutCostPerGb = 0;

    if (od.region === 'internet') {
      dataOutCostPerGb = ec2Costs.regionCosts.dataOutCostsPerGbToInternet;
    } else {
      dataOutCostPerGb =
        ec2Costs.regionCosts.dataOutCostsPerGbToRegion[od.region];
    }

    const GB_perHourOD = getGBPerHour({ bitrateKbps: od.bitrateKbps });

    TOTAL_COST_destinations_data_out += GB_perHourOD * dataOutCostPerGb;
  });

  // -------------- DISK cost -----------------------------

  const hoursInAMonth = 30 * 24;
  const TOTAL_COST_DISK =
    (SERVER_GP3_STORAGE_GB * ec2Costs.regionCosts.gp3StoragePerGbMonth) /
    hoursInAMonth;

  // -------------- COMPUTE ------------------------------

  const TOTAL_COST_COMPUTE = ec2Costs.computePerHour;

  // ------------ result 1 --------------------

  const TOTAL_CLOUD_PROD_COST =
    TOTAL_COST_previews_data_out +
    TOTAL_COST_hls_s3_data_out +
    TOTAL_COST_destinations_data_out +
    TOTAL_COST_COMPUTE +
    TOTAL_COST_DISK;

  breakDownString = appendToString(
    breakDownString,
    `\n\n==== Cloud Prod: COST ($/hr) (with ${TAX_MULTIPLIER} tax multiplier)\n`,
  );

  breakDownString = appendToString(
    breakDownString,
    `\n(data-out) PREVIEWS: ${TOTAL_COST_previews_data_out * TAX_MULTIPLIER}`,
  );

  breakDownString = appendToString(
    breakDownString,
    `\n(data-out) HLS s3 uploads: ${TOTAL_COST_hls_s3_data_out *
      TAX_MULTIPLIER}`,
  );

  breakDownString = appendToString(
    breakDownString,
    `\n(data-out) DESTINATIONS: ${TOTAL_COST_destinations_data_out *
      TAX_MULTIPLIER}`,
  );

  breakDownString = appendToString(
    breakDownString,
    `\n(compute): ${TOTAL_COST_COMPUTE * TAX_MULTIPLIER}`,
  );

  breakDownString = appendToString(
    breakDownString,
    `\n(disk): ${TOTAL_COST_DISK * TAX_MULTIPLIER}`,
  );

  breakDownString = appendToString(
    breakDownString,
    `\n\nTOTAL: ${TOTAL_CLOUD_PROD_COST * TAX_MULTIPLIER}`,
  );

  // ================ SECTION 2: S3 Storage ===============================

  const s3Costs = RegionBasedS3CostsConfig[request.s3Region];

  const STREAM_STORAGE_GB = GB_perHourHls;

  const numberOfStreamObjects =
    request.hlsQualityLevels.length *
    (1 + 3600 / request.hlsSegmentDurationSeconds);

  const totalStreamStorageMonths =
    request.streamS3StorageFAMonths +
    request.streamS3StorageIFAMonths +
    request.streamS3StorageAIAMonths;

  const COST_INT_monitoringStream =
    (numberOfStreamObjects *
      totalStreamStorageMonths *
      s3Costs.INT_monitoringPer1000Objects) /
    1000;

  const PUT_REQUESTS_COST = request.hlsQualityLevels.length > 0 ? 0.0095 : 0;

  const COST_steamStorage =
    (request.streamS3StorageFAMonths * s3Costs.INT_FA_GBMonth +
      request.streamS3StorageIFAMonths * s3Costs.INT_IFA_GBMonth +
      request.streamS3StorageAIAMonths * s3Costs.INT_AIA_GBMonth) *
      STREAM_STORAGE_GB +
    COST_INT_monitoringStream +
    PUT_REQUESTS_COST;

  let highestHlsBitrateKbps = 0;
  request.hlsQualityLevels.forEach(e => {
    if (e.bitrateKbps > highestHlsBitrateKbps)
      highestHlsBitrateKbps = e.bitrateKbps;
  });

  const HIGHLIGHTS_STORAGE_GB =
    (request.minsOfHighlightsPerHour / 60) *
    getGBPerHour({ bitrateKbps: highestHlsBitrateKbps });

  const numberOfHighlightObjects =
    request.minsOfHighlightsPerHour > 0 ? 100 : 0; // approx

  const totalHighlightStorageMonths =
    request.highlightS3StorageFAMonths +
    request.highlightS3StorageIFAMonths +
    request.highlightS3StorageAIAMonths;

  const COST_INT_monitoringHighlight =
    (numberOfHighlightObjects *
      totalHighlightStorageMonths *
      s3Costs.INT_monitoringPer1000Objects) /
    1000;

  const COST_highlightStorage =
    (request.highlightS3StorageFAMonths * s3Costs.INT_FA_GBMonth +
      request.highlightS3StorageIFAMonths * s3Costs.INT_IFA_GBMonth +
      request.highlightS3StorageAIAMonths * s3Costs.INT_AIA_GBMonth) *
      HIGHLIGHTS_STORAGE_GB +
    COST_INT_monitoringHighlight;

  const TOTAL_COST_S3_STORAGE = COST_highlightStorage + COST_steamStorage;

  breakDownString = appendToString(
    breakDownString,
    `\n\n==== S3 ($/hr) (with ${TAX_MULTIPLIER} tax multiplier)n`,
  );

  breakDownString = appendToString(
    breakDownString,
    `\n(s3-storage) stream: ${COST_steamStorage * TAX_MULTIPLIER}`,
  );

  breakDownString = appendToString(
    breakDownString,
    `\n(s3-storage) highlights: ${COST_highlightStorage * TAX_MULTIPLIER}`,
  );

  breakDownString = appendToString(
    breakDownString,
    `\n\n(s3-storage) TOTAL: ${TOTAL_COST_S3_STORAGE * TAX_MULTIPLIER}`,
  );

  // ================ SECTION 3: Clipping CDN (distribution) ===============================

  const GB_stream_highest_quality = getGBPerHour({
    bitrateKbps: highestHlsBitrateKbps,
  });

  const COST_highlight_creation_and_qa =
    request.numberOfHighlightClippersAndQA *
    GB_stream_highest_quality *
    CDN_MUMBAI_DATA_OUT_EGRESS_COST_PER_GB;

  const COST_highlight_download =
    request.numberOfHighlightDownloads *
    HIGHLIGHTS_STORAGE_GB *
    CDN_MUMBAI_DATA_OUT_EGRESS_COST_PER_GB;

  const TOTAL_COST_CLIPPING_CDN =
    COST_highlight_creation_and_qa + COST_highlight_download;

  breakDownString = appendToString(
    breakDownString,
    `\n\n==== Clipping CDN (distribution) ($/hr) (with ${TAX_MULTIPLIER} tax multiplier)n`,
  );

  breakDownString = appendToString(
    breakDownString,
    `\n(distribution) clipper / qa: ${COST_highlight_creation_and_qa *
      TAX_MULTIPLIER}`,
  );

  breakDownString = appendToString(
    breakDownString,
    `\n(distribution) highlights download: ${COST_highlight_download *
      TAX_MULTIPLIER}`,
  );

  breakDownString = appendToString(
    breakDownString,
    `\n\n(distribution) clipping TOTAL: ${TOTAL_COST_CLIPPING_CDN *
      TAX_MULTIPLIER}`,
  );

  // ================ SECTION 4: Viewership CDN (distribution) ===============================

  const TOTAL_COST_VIEWERSHIP_CDN =
    request.avgNumberOfUsersWhoWillWatchFullStream *
    GB_stream_highest_quality *
    CDN_MUMBAI_DATA_OUT_EGRESS_COST_PER_GB;

  breakDownString = appendToString(
    breakDownString,
    `\n\n====Viewership CDN (distribution) ($/hr) (with ${TAX_MULTIPLIER} tax multiplier) \n`,
  );

  breakDownString = appendToString(
    breakDownString,
    `\n\n(distribution) Viewership: TOTAL: ${TOTAL_COST_VIEWERSHIP_CDN *
      TAX_MULTIPLIER}`,
  );

  // ================ TOTAL COST ==========================

  const GRAND_TOTAL_SERVER =
    TOTAL_COST_CLIPPING_CDN + TOTAL_COST_S3_STORAGE + TOTAL_CLOUD_PROD_COST;

  const GRAND_TOTAL_CDN = TOTAL_COST_VIEWERSHIP_CDN;

  let grandTotalString = '';
  grandTotalString = appendToString(
    grandTotalString,
    `\n\nGRAND TOTAL ($/hr) (with ${TAX_MULTIPLIER} tax multiplier) \n`,
  );

  grandTotalString = appendToString(
    grandTotalString,
    `\n\n(server) TOTAL: ${GRAND_TOTAL_SERVER * TAX_MULTIPLIER}`,
  );

  grandTotalString = appendToString(
    grandTotalString,
    `\n\n(distribution) Viewership: TOTAL: ${GRAND_TOTAL_CDN *
      TAX_MULTIPLIER}\n\n`,
  );

  const reportString = `${grandTotalString}${breakDownString}`;

  console.log(reportString);

  return reportString;
};
